import { DefaultButton, DetailsList, PrimaryButton, SelectionMode, Spinner } from "@fluentui/react";
import axios from 'axios';
import { saveAs } from 'file-saver';
import numeral from 'numeral';
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as XLSX from 'xlsx';
import config from '../Admin/config';





const FormularioConsolidado2 = ({companyId}) => {
    const [documentos, setDocumentos] = useState([]);
    const [disabledCalcular , setDisabledCalcular] = useState(false)
    const [disabledConsolidar, setDisabledConsolidar] = useState(true)
    const [disabledExcel, setDisabledExcel] = useState(true)
    const [isLoading, setIsLoading] = useState(true); // Track loading state
    const navigate = useNavigate();
    

    const {t} = useTranslation();

    const { id } = useParams();
    numeral.locale('en'); // Change 'en' to the desired locale

    const exportToExcel = (data, columns) => {
      const worksheetData = data.map((item) => {
        const rowData = {
          id: id 
        };
        columns.forEach((column) => {
          rowData[column.fieldName] = item[column.key];
        });
        return rowData;
      });
    
      const worksheet = XLSX.utils.json_to_sheet(worksheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      const excelBlob = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(excelBlob, id + '.xlsx');
    };
    

    const handleConsolidar = async () => {
     
      try {
          // Deshabilitar el botón cuando el proceso empieza
          setDisabledConsolidar(true);
  
          await axios.patch(`${config.apiUrl}api/documents/${id}`, null, 
          { params: { company: companyId } })
              .then(response => response.data)
              .then(result => {
                  if (result.isSuccessStatusCode) {
                      navigate(`/documento`);
                  } else {
                      alert(t('Consolidate.Error'));
                  }
              });
      } catch (error) {
          alert(t('Consolidate.Error')); // Maneja el error de manera apropiada aquí
      } finally {
          // Volver a habilitar el botón cuando el proceso termine (ya sea con éxito o error)
          setDisabledConsolidar(false);
      }
  };

    const startAutoRefresh = () => {
      const intervalId = setInterval(async () => {
        try {
          await axios.get(`${config.apiUrl}api/documents/calculate?transportDocument=${id}`);
        } catch (error) {
          await fetchDocumentoData(); // Call fetchItemsData on 404 response
          setIsLoading(false);
          setDisabledCalcular(false);
          clearInterval(intervalId);
        }
      }, 5000); // 10 seconds interval
    };

    const handleCalcular = async () => {
      setDisabledCalcular(true)
      try {
          await axios.patch(`${config.apiUrl}api/documents/calc/MACISGC/${id}`, null, 
          {params:{
              company : companyId }})
              .then(response => response.data)
              .then(result => 
                {
                  if (!result) 
                  {
                      alert(t('Consolidate.Error'))
                  }
                  else
                  {
                      setIsLoading(true)
                      startAutoRefresh(); 
                      alert(t('Consolidate.Calculating'))                      
                  } 
                }
              )    
     } catch (error) {
        alert(t('Falta llenar el campo de Levante y actualizar la fecha de Levante para poder continuar.'))// Handle the error gracefully here, e.g., show an error message to the user
     }
     
    };

    const fetchDocumentoData = useCallback(async () => {
      try {
        const response = await axios.get(`${config.apiUrl}api/items/${id}`, {
          params: {
            company: companyId,
          },
        });
        const data = response.data;
        console.log(data)
        setDocumentos(data);
        if (data.length > 0) {
          setDisabledExcel(false);
          setDisabledConsolidar(false);
        }
        setIsLoading(false); // Turn off loading state on successful fetch
      } catch (error) {
        alert("Error fetching data:")
        setIsLoading(false);
      }
    }, [companyId, id]);

    useEffect(() => {
        fetchDocumentoData();
    }, [companyId, fetchDocumentoData]);


    const columns = [
      { key: 'vendorAccount', name: t('Consolidate.Provider'), fieldName: 'vendorAccount', minWidth: 100, maxWidth: 200 },
      { key: 'purchOrderId', name: t('Consolidate.POrder'), fieldName: 'purchOrderId', minWidth: 100, maxWidth: 200 },
      { key: 'purchorderLine', name: t('Consolidate.Line'), fieldName: 'purchorderLine', minWidth: 100, maxWidth: 200 },
      { key: 'itemId', name: t('Consolidate.ItemId'), fieldName: 'itemId', minWidth: 100, maxWidth: 200},
      { key: 'itemName', name: t('Consolidate.ItemName'), fieldName: 'itemName', minWidth: 100, maxWidth: 200 },
      { key: 'quantity', name: t('Consolidate.Quantity'), fieldName: 'quantity', minWidth: 100, maxWidth: 200 },
      { key: 'costType', name: t('Consolidate.MarkUp'), fieldName: 'costType', minWidth: 100, maxWidth: 200 },
      { key: 'description', name: t('Consolidate.Description'), fieldName: 'description', minWidth: 100, maxWidth: 200 },
      { key: 'landedCostDocument', name: t('Consolidate.LandedCost'), fieldName: 'landedCostDocument', minWidth: 100, maxWidth: 200 },
      { key: 'currency', name: t('Consolidate.Currency'), fieldName: 'currency', minWidth: 100, maxWidth: 200 },
      { key: 'exchangeRate', name: t('Consolidate.Exchange'), fieldName: 'exchangeRate', minWidth: 100, maxWidth: 200 },
      { key: 'transportDocumentDate', name: t('Consolidate.BillDate'), fieldName: 'transportDocumentDate', minWidth: 100, maxWidth: 200 },
      { key: 'chargePercent', name: t('Consolidate.Percent'), fieldName: 'chargePercent', minWidth: 100, maxWidth: 200 ,onRender: (item) => (item.chargePercent).toFixed(2) + '%'},
      { key: 'chargeAmount', name: t('Consolidate.Amount'), fieldName: 'chargeAmount', minWidth: 100, maxWidth: 200 , onRender: (item) => numeral(item.chargeAmount).format('$0,0.00')},
      { key: 'nitTerceroColombia', name: 'Nit', fieldName: 'nitTerceroColombia', minWidth: 100, maxWidth: 200 },
      { key: 'invoice', name: t('Factura'), fieldName: 'invoice', minWidth: 100, maxWidth: 200 },
      { key: 'documentdate', name: t('Fecha factura'), fieldName: 'documentdate', minWidth: 100, maxWidth: 200 },
      { key: 'equipmentId', name: t('Equipment'), fieldName: 'equipmentId', minWidth:100, maxWidth :200}
    ]
      return (
        <div  className="card">
            <h1>{t('View.Header') + " " + id} </h1>
              {isLoading && // Show loader when loading
              <Spinner label={t("Loading")} />}
              <DetailsList
                  items={documentos}
                  selectionMode={SelectionMode.none}
                  columns={columns}
              />
              <PrimaryButton onClick={handleCalcular} disabled={disabledCalcular}>{t('Consolidate.Calc')}</PrimaryButton>
              <PrimaryButton onClick={handleConsolidar} disabled={disabledConsolidar}>{t('Consolidate.Consolidate')}</PrimaryButton>
              <DefaultButton onClick={() => exportToExcel(documentos, columns)} disabled={disabledExcel}>{t('Consolidate.Excel')}</DefaultButton>
        </div>
    );
}; 

const mapStateToProps = (state) => ({
    companyId: state.companyId,
});

export default connect(mapStateToProps)(FormularioConsolidado2);
